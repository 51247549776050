/* ------- */
/* Overall */
/* ------- */
#Main {
  width: 100vw;
  height: 105vh;
  background-image: url("../../../public/img/bg/6382546.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  color: rgb(87, 47, 124);
}

#Main:root {
  --t: "";
  --color: "";
  --x: 0;
  --y: 0;
  --deg: 0;
}

.bgFrame {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/img/bg/bgFrame1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 1.7s ease-in 0.5s forwards;
}

.bgFrame2 {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 30vw;
  height: 30vw;
  background-image: url("../../../public/img/bg/bgFrame3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 0;
  opacity: 0.3;
}

.bgFrame3 {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(-90deg);

  width: 30vw;
  height: 30vw;
  background-image: url("../../../public/img/bg/bgFrame3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 0;
  opacity: 0.3;
}

@keyframes fadeIn_xALL {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 0.5;
    pointer-events: all;
  }
}

/* ----- */
/* title */
/* ----- */
#Main > h1 {
  position: fixed;
  left: 50%;
  top: 2vh;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  height: 120px;
  width: fit-content;
  padding-top: 25px;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn_xALL 1.7s ease-in 0.3s forwards;
  transition: 1s;
  color: rgb(87, 47, 124);
  background-image: url("../../../public/img/bg/title.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bounce {
  display: inline-block;
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translateY(-10px);
    animation-timing-function: ease-out;
  }
}

/* ------ */
/* Header */
/* ------ */
#header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/img/Main/header.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: rgb(255, 255, 255);
  animation: slideDown 0.8s ease-out;
  z-index: 10;
  overflow: hidden;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

#meImg img {
  margin-left: 20px;
  width: 150px;
  height: auto;
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
}

.tip {
  position: absolute;
  bottom: 2rem;
  animation: swing2 1.7s ease-in-out infinite;
}

.tip2 {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 50%);

  margin: 0;
  animation: swing 1.7s ease-in-out infinite;
  opacity: 0;
  pointer-events: none;
}

@keyframes swing {
  10%,
  90% {
    transform: translate(-50%, 50%) translate3d(-5px, 0, 0);
  }

  20%,
  80% {
    transform: translate(-50%, 50%) translate3d(10px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate(-50%, 50%) translate3d(-20px, 0, 0);
  }

  40%,
  60% {
    transform: translate(-50%, 50%) translate3d(20px, 0, 0);
  }
}

@keyframes swing2 {
  10%,
  90% {
    transform: translate3d(-3px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(6px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-9px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(9px, 0, 0);
  }
}

/* ---------------- */
/* Info chunk outer */
/* ---------------- */
#Main main {
  animation: fadeIn 1.7s ease-in forwards;
}

.outerTitle {
  animation: fadeIn 1.7s ease-in forwards;
  pointer-events: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

#infos {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 36vh);
  justify-items: center;
  align-items: center;

  width: 100vw;
  max-width: 1200px;
}

.info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 25px;
  padding: 10px;
  padding-left: 30px;
  height: 30vh;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgb(255, 255, 255, 0.7);
  border-radius: 14px;
  cursor: pointer;
  pointer-events: auto;
  backdrop-filter: blur(3px);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

/* .info::before {
  content: "";
  position: absolute;
  left: -50%;
  top: -50%;

  width: 200%;
  height: 200%;
  background-image: conic-gradient(
    from 0deg,
    transparent 0%,
    rgba(233, 219, 240, 0.8) 25%,
    rgba(219, 199, 228, 1) 50%,
    rgba(233, 219, 240, 0.8) 75%,
    transparent 100%
  );
  z-index: -2;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
  }

  to {
    transform: rotate(360deg);
  }
} */

.info:hover {
  background-color: rgba(193, 168, 196, 0.6);
}

.basic {
  grid-row: 1;
  grid-column: 1;
}

.pictures {
  grid-row: 1;
  grid-column: 2;
  padding: 0;
}

.education {
  grid-row: 2;
  grid-column: 1;
}

.skill {
  grid-row: 2;
  grid-column: 2;
}

.decorate {
  position: absolute;

  width: 250px;
  height: 250px;
  background-image: url("../../../public/img/Main/紫藤花.gif");
  background-size: cover;
  z-index: -1;
  opacity: 0.8;
}

.basic .decorate {
  left: -10px;
  top: 0px;
  transform: rotate(-20deg);
}

.pictures .decorate {
  right: -10px;
  top: 0px;
  transform: rotate(20deg) rotateY(180deg);
}

.decorate2 {
  position: absolute;

  width: 200px;
  height: 200px;
  background-image: url("../../../public/img/Main/flower.png");
  background-size: cover;
  z-index: -1;
  opacity: 0.5;
}

.education .decorate2 {
  left: -10px;
  bottom: -25px;
}

.skill .decorate2 {
  right: -10px;
  bottom: -25px;
  transform: rotateY(180deg);
}

.showText {
  position: absolute;

  transition: 0.3s;
  opacity: 0;
}

/* ---------------- */
/* Info chunk inner */
/* ---------------- */
.inner {
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: scroll;
}

.inner strong {
  animation: none;
  transition: none;
}

/* 用于blur */
.outer {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  backdrop-filter: blur(6px);
  border-radius: 14px;
  z-index: -1;
  pointer-events: none;
}

.inner h3 {
  margin: 0;
  padding: 1.5rem 0;
}

#Main main ul {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;
}

#Main main ul li,
#Main main p {
  padding: 10px 20px;
  padding-left: 0;
}

#Main .info li {
  animation: fadeIn 0.1s ease-in forwards;
}

/* Gallery */
.imgGallery_container {
  position: absolute;

  height: 100%;
  width: 100%;
}

.skill ul {
  list-style: none;
}

/* skill的背景 */
.scrolls_container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100vw;
  overflow: hidden;
  mask-image: linear-gradient(
    90deg,
    transparent,
    white 10%,
    white 90%,
    transparent
  );
  z-index: -1;
  animation: fadeIn_xALL 1s linear forwards;
}

.scroll_container {
  position: relative;
  display: flex;
  overflow: hidden;
}

.scroll_container div {
  white-space: nowrap;
  animation: scroll var(--t) linear infinite;
}

.scroll_container div:nth-child(2) {
  white-space: nowrap;
  animation: scroll2 var(--t) linear calc(var(--t) / -2) infinite;
}

.scroll_container span {
  display: inline-flex;
  margin: 6vh 10px;
  letter-spacing: 2px;
  background-color: rgba(196, 171, 196, 0.6);
  color: rgb(105, 94, 116);
  padding: 5px 10px;
  border-radius: 5px;
}

@keyframes scroll {
  from {
    transform: translate(100%);
  }

  to {
    transform: translate(-100%);
  }
}

@keyframes scroll2 {
  from {
    transform: translate(0);
  }

  to {
    transform: translate(-200%);
  }
}

/* ---- */
/* menu */
/* ---- */
.centerMenu_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: fit-content;
  z-index: 10;
  cursor: pointer;
}

.centerMenuButton_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  z-index: 10;
}

.centerMenuButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  border-radius: 50%;
  background-image: url("../../../public/img/Main/button.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* background-image: linear-gradient(45deg, rgb(201, 174, 226), rgb(220, 205, 235), rgb(255, 255, 255, 0.35)); */
  overflow: hidden;
}

.centerMenuButton:hover {
  transform: scale(1.2);

  text-shadow: 1px 1px 10px var(--color);
  border: none;
}

.centerMenuButton::before {
  content: "";
  position: absolute;
  top: var(--y);
  left: var(--x);
  transform: translate(-50%, -50%);

  width: 100px;
  height: 100px;
  background: radial-gradient(rgb(174, 0, 255), transparent, transparent);
  opacity: 0;
  transition: 0.3s, top 0s, left 0s;
}

.centerMenuButton::after {
  content: "";
  position: absolute;

  background-image: url("../../../public/img/Main/button.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset: 2px;
  border-radius: 50%;
}

.centerMenuButton:hover::before {
  opacity: 1;
}

.circle {
  position: absolute;
  display: flex;
  transform: scale(1.2);

  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-image: url("../../../public/img/Main/circle.png");
  background-size: cover;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: scale(1.2) rotate(0);
  }

  to {
    transform: scale(1.2) rotate(360deg);
  }
}

.circle_bg {
  position: absolute;

  width: 50vmin;
  height: 50vmin;
  border-radius: 50%;
  background-color: rgba(192, 172, 212, 0.8);
  filter: blur(5px);
}

.circle_bg:nth-child(2) {
  width: 65vmin;
  height: 65vmin;
  background-color: rgba(192, 172, 212, 0.4);
  background-image: none;
  filter: none;
}

/* 打开之后 */
.centerMenu_container .icon {
  width: 40px;
  height: 40px;
  pointer-events: none;
}

.nav_container {
  position: relative;

  animation: fadeIn 0.75s ease-in forwards;
}

.nav_container > div {
  position: absolute;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%) rotate(var(--deg)) translateX(150px)
    rotate(calc(var(--deg) * -1));

  padding: 10px;
  width: 120px;
  height: 70px;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  animation: circularMotion 10s linear infinite;
}

.nav_container > div:hover {
  box-sizing: unset;
  border: 5px solid white;
}

.nav_container > div div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

@keyframes circularMotion {
  0% {
    transform: translate(-50%, -50%) rotate(var(--deg)) translateX(170px)
      rotate(calc(var(--deg) * -1));
  }

  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--deg) + 360deg))
      translateX(170px) rotate(calc(var(--deg) * -1 - 360deg));
  }
}

.centerMenu_container:hover .nav_container div {
  animation-play-state: paused;
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
  #Main main {
    /* 用于页面本身不考虑滑动的情况 */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: scale(0.8) translate(-50%, -50%);
    transform-origin: top left;
  }

  @keyframes circularMotion {
    0% {
      transform: translate(-50%, -50%) rotate(var(--deg)) translateX(120px)
        rotate(calc(var(--deg) * -1));
    }

    100% {
      transform: translate(-50%, -50%) rotate(calc(var(--deg) + 360deg))
        translateX(120px) rotate(calc(var(--deg) * -1 - 360deg));
    }
  }

  #Main > h1 {
    font-size: 1.2rem;
  }
}
