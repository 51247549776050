/* 针对英文字符使用Indie Flower字体 */
:lang(en) {
  font-family: "Indie Flower", cursive;
}

/* 针对中文字符使用Long Cang字体 */
:lang(zh) {
  font-family: "Long Cang", cursive;
}

* {
  box-sizing: border-box;
  user-select: none;
  transition: 0.3s;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar-thumb {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body {
  display: flex;
  flex-direction: column;

  margin: 0;
  background-color: rgba(241, 233, 245, 0.8);
  overflow-x: hidden;
}

main {
  flex: 1;
}

a {
  text-decoration: none;
}

.color_change {
  background: linear-gradient(
    45deg,
    #f3dae4,
    #d7d6ee,
    #d9f3f4,
    #dcf5db,
    #f0e2d6,
    #dcf5db,
    #d9f3f4,
    #d7d6ee,
    #f3dae4
  );
  background-size: 200% 200%;
  animation: smoothColorShift 5s infinite linear;
}

.color_change2 {
  background: linear-gradient(
    45deg,
    #d4c7ff,
    #d5bdec,
    #d0f4df,
    #e8d9ec,
    #d4e0ff,
    #e8d9ec,
    #d0f4df,
    #d5bdec,
    #d4c7ff
  );
  background-size: 200% 200%;
  animation: smoothColorShift 8s infinite linear;
}

.color_change3 {
  background: linear-gradient(
    45deg,
    #e5c6d6,
    #c6c4e2,
    #c1dfe0,
    #c7e4c6,
    #e3d0c3,
    #c7e4c6,
    #c1dfe0,
    #c6c4e2,
    #e5c6d6
  );
  background-size: 200% 200%;
  animation: smoothColorShift 5s infinite linear;
}

@keyframes smoothColorShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
