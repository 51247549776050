/* ------- */
/* Overall */
/* ------- */
#Employment {
  width: 100vw;
  height: max-content;
  background-image: url("../../../public/img/bg/6384984.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#Employment:root {
  --w: 0;
  --deg: 0;
}

.bgFrame {
  position: fixed;
  bottom: -50vh;
  left: 0;

  width: 100vw;
  height: 35vh;
  background-image: url("../../../public/img/bg/bgFrame8.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

.bgFrame2 {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 25vh;
  background-image: url("../../../public/img/bg/bgFrame7.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

#Employment footer {
  position: relative;

  margin-top: 20px;
}

/* ----- */
/* title */
/* ----- */
.title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 30vmax;
  height: 85px;
  color: rgb(106, 35, 109);
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  animation: slideDown 1.5s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}

.title h1 {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

/* ---------- */
/* Employment */
/* ---------- */
.decorate {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  opacity: 0;
  animation: fadeIn 1.7s ease-in 1s forwards;
}

.decorate div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(101, 64, 136, 0.6);
}

.decorate::before,
.decorate::after {
  content: "";
  display: flex;

  margin: 0 20px;
  width: 35vw;
  height: 20px;
  background-image: url("../../../public/img/Employment/line.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.decorate_bg {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);

  width: 200px;
  height: 200px;
  background-image: url("../../../public/img/Employment/风铃.gif");
  background-size: cover;
  z-index: -1;
  opacity: 0.8;
}

.employments {
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto auto;

  margin: auto;
  padding-top: 150px;
  width: 100vw;
  max-width: 1200px;
}

.employment {
  position: relative;
  perspective: 3000px;
  transform-style: preserve-3d;

  animation: fadeIn 1.7s ease-in forwards;
  animation-delay: 1.5s;
  opacity: 0;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

.employment h1 {
  color: rgb(141, 101, 179);
}

.employment li {
  margin-top: 5px;
  list-style-position: inside;
}

.bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;
  opacity: 0;
  transition: 0.8s;
}

.employment:hover .bg {
  opacity: 0.1;
}

.bg img {
  width: 25vmax;
  height: 25vmax;
  border-radius: 50%;
  border: 5px solid black;
}

/* 卡片内部 */
.turnSide {
  position: relative;

  margin: 40px;
  padding: 20px;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  overflow: hidden;
  backface-visibility: hidden;
  transition: 0.5s;
  pointer-events: none;
}

/* .turnSide:nth-child(1)::before {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;

    width: 200%;
    height: 200%;
    background-image: conic-gradient(from 0deg, transparent 0%, rgba(233, 219, 240, 0.2) 25%, rgba(219, 199, 228, 0.6) 50%, rgba(233, 219, 240, 0.2) 75%, transparent 100%);
    z-index: -2;
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    from {}

    to {
        transform: rotate(360deg);
    }
} */

.employment .turnSide:nth-child(1) {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employment .turnSide:nth-child(2) {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

#Employment a {
  font-weight: bold;
  font-style: italic;
  pointer-events: all;
}

/* ------- */
/* 动画路线 */
/* ------- */
.scrollAnimation {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
  width: 320px;
  height: 320px;
  /* animation-timing-function: ease-in; */
  /* animation: scrollDown forwards;
    animation-timeline: scroll(root); */
  z-index: 10;
  transition: none;
}

@keyframes scrollDown {
  0% {
  }

  33% {
    transform: translate(calc(var(--w) * -1), var(--h1));
  }

  66% {
    transform: translate(0, calc(var(--h2) + var(--h1)));
  }

  100% {
    transform: translate(
      calc(var(--w) * -1),
      calc(var(--h3) + var(--h2) + var(--h1))
    );
  }
}

.circle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-image: url("../../../public/img/Employment/animation.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  animation: fadeIn 0.75s ease-in forwards;
  animation-delay: 1.7s;
  cursor: pointer;
  pointer-events: none;
}

.circle:hover {
  transform: scale(1.1);
}

.circle div {
  animation: float 2.1s ease-in-out 0.5s infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }
}

.path {
  position: relative;
  grid-column: 2;
  grid-row: 1;

  width: 320px;
  height: 320px;
}

.line {
  position: absolute;
  left: -2px;
  transform-origin: top;

  width: 3px;
  height: 0;
  background-color: transparent;
  border-left: 3px double rgba(138, 43, 226, 0.8);
}

/* -------- */
/* 小屏Menu */
/* -------- */
.icon {
  width: 25px;
  height: auto;
}

.menuButton {
  position: absolute;
  transform: rotate(var(--deg)) translateX(120px) rotate(calc(var(--deg) * -1));

  padding: 10px 20px;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  cursor: pointer;
  opacity: 0;
  animation: circularMotion 10s linear infinite;
}

@keyframes circularMotion {
  0% {
    transform: rotate(var(--deg)) translateX(120px)
      rotate(calc(var(--deg) * -1));
  }

  100% {
    transform: rotate(calc(var(--deg) + 360deg)) translateX(120px)
      rotate(calc(var(--deg) * -1 - 360deg));
  }
}

.menuButton:hover {
  background-color: rgb(187, 166, 193);
}

.scrollAnimation:hover .menuButton {
  animation-play-state: paused;
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
  #Employment main {
    /* 用于页面本身需要考虑滑动的情况 */
    position: absolute;
    top: 0;
    left: 50%;
    transform: scale(0.8) translateX(-50%);
    transform-origin: top left;
  }

  .decorate {
    top: 150px;
  }

  .employments {
    display: flex;
    flex-direction: column;
  }

  .turnSide {
    margin: 40px 0px;
  }

  .scrollAnimation {
    display: none;
  }

  .path {
    display: none;
  }
}
