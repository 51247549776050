footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 0;
    color: rgb(0, 0, 0, 0.4);
    animation: fadeIn 1.7s ease-in forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        pointer-events: none;
    }

    99% {
        pointer-events: none;
    }

    100% {
        opacity: 1;
        pointer-events: all;
    }
}

p {
    margin-top: 0;
}

p:nth-child(2) {
    cursor: pointer;

}

/* ----- */
/* card  */
/* ----- */
.card_container {
    position: fixed;
    top: -50vh;
    transform: translateY(-50%);
    perspective: 3000px;
    transform-style: preserve-3d;
    opacity: 0;
}

.front {
    width: 50vmax;
    height: 30vmax;
    background-image: url("../../../public/img/1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    backface-visibility: hidden;
    border-radius: 14px;
}

.back {
    position: absolute;
    top: 0;
    width: 50vmax;
    height: 30vmax;
    background-image: url("../../../public/img/2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    border-radius: 14px;
}

.card_container:hover .back {
    transform: rotateY(0);
}

.card_container:hover .front {
    transform: rotateY(-180deg);
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
    footer {
        z-index: -1;
    }
}