/* ------- */
/* Overall */
/* ------- */
#Research {
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  background-image: url("../../../public/img/bg/6384984.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

#Research main {
  min-height: 100vh;
}

#Research footer {
  position: relative;

  margin-top: 20px;
}

#Research:root {
  --h: "";
}

.bgFrame {
  position: fixed;
  bottom: -50vh;
  left: 0;

  width: 100vw;
  height: 35vh;
  background-image: url("../../../public/img/bg/bgFrame8.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.bgFrame2 {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 25vh;
  background-image: url("../../../public/img/bg/bgFrame7.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

/* ----- */
/* menu */
/* ----- */
/* .menu_container {
  opacity: 0;
  animation: fadeIn 1s ease-in 1s forwards;
}

.submenu .icon {
  transform: rotate(-90deg);

  width: 50px;
  height: auto;
}

.menu .icon {
  width: 25px;
  height: auto;
}

.submenu {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  width: fit-content;
  height: fit-content;
  animation: enlarge 1.5s ease-in-out infinite;
  cursor: pointer;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.menu {
  position: fixed;
  right: -300px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  padding: 80px 40px;
  height: 90vh;
  width: 300px;
  border-radius: 14px 0 0 14px;
  color: rgb(106, 35, 109);
  background-image: url("../../../public/img/Research/menu.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 10;
  overflow: hidden;
}

.menu::before {
  content: "";
  position: absolute;

  width: 400%;
  height: 200%;
  background-image: conic-gradient(
    from 0deg,
    transparent 0%,
    rgba(186, 156, 201, 0.3) 25%,
    rgba(186, 156, 201, 0.5) 50%,
    rgba(186, 156, 201, 0.3) 75%,
    transparent 100%
  );
  z-index: -2;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
  }

  to {
    transform: rotate(360deg);
  }
}

.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 100%;
  height: 100%;
}

.submenu:hover {
  opacity: 0.6;
}

.closeMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
} */

/* ---- */
/* info */
/* ---- */
.info_container {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  grid-template-rows: auto auto;

  margin: auto;
  padding-top: 170px;
  width: 99vw;
  max-width: 1000px;
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 1s ease-in 1s forwards;
}

.info {
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 30px;
  margin-bottom: 50px;
  width: 100%;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 14px;
  border: 2px solid white;
  cursor: pointer;
}

.info:hover {
  border: 2px solid rgb(154, 133, 162);
}

.info:nth-child(1) {
  grid-column: 1 / span 2;
}

.info:nth-child(2) {
  grid-row: 2;
  grid-column: 2 / span 3;
}

.detail {
  height: 100%;
  pointer-events: none;
}

.img_container {
  width: calc(100%);
  height: 100%;

  background-image: url("../../../public/img/Research/ai.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: rgb(255, 235, 252, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  border: 4px double white;
}

.info:nth-child(2) .img_container {
  background-image: url("../../../public/img/Research/ai.png");
}

.info h2 {
  text-align: center;
  margin-bottom: 0;
  pointer-events: none;
}

.text {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

/* ----- */
/* title */
/* ----- */
.decorate {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  opacity: 0;
  animation: fadeIn 1.7s ease-in 1s forwards;
  z-index: 10;
  pointer-events: none;
}

.decorate > div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(101, 64, 136, 0.6);
  font-size: 0.8rem;
  color: rgb(106, 35, 109);
  animation: enlarge 3s ease-in-out infinite;
  cursor: pointer;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.decorate > div:hover .decorate > div {
  opacity: 0.5;
}

.decorate::before,
.decorate::after {
  content: "";
  display: flex;

  margin: 0 20px;
  width: 35vw;
  height: 20px;
  background-image: url("../../../public/img/Employment/line.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menu {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  padding: 25px;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 14px;
  border: 1px solid white;
  z-index: 10;
  opacity: 0;
}

.menu div {
  padding: 2px;
  cursor: pointer;
}

.menu div:hover {
  background-color: rgb(255, 255, 255, 0.6);
  border-radius: 7px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  opacity: 0.5;
}

.icon {
  width: 25px;
  height: auto;
}

.title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 30vmax;
  height: 85px;
  color: rgb(106, 35, 109);
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  animation: slideDown 1.5s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}

.title h1 {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
  #Research main {
    position: relative;
    top: 0px;
    left: 50%;
    transform: scale(0.8) translateX(-50%);
    transform-origin: top left;
  }

  #Research {
    height: var(--h);
    overflow: hidden;
  }

  .decorate {
    top: 150px;
  }

  .info_container {
    display: block;
    padding-top: 200px;
  }

  .info {
    top: none;
    transform: translateX(-50%);
  }
}
