/* ------- */
/* Overall */
/* ------- */
#ProjectDetail {
  position: relative;

  width: 100vw;
  height: max-content;
  background-image: url("../../../public/img/bg/6384984.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#ProjectDetail footer {
  position: relative;

  margin-top: 20px;
}

#ProjectDetail:root {
  --h: "";
  --w: "";
}

#ProjectDetail footer {
  position: relative;

  margin-top: 20px;
}

/* ----- */
/* 特殊键 */
/* ----- */
.goBack {
  position: absolute;
  top: 20px;
  left: 20px;

  font-size: 2rem;
  color: rgb(179, 147, 172);
  cursor: pointer;
}

.goBack:hover {
  color: rgb(102, 63, 94);
}

.toTop {
  position: fixed;
  bottom: 20px;
  right: 20px;

  width: 50px;
  height: 50px;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  animation: enlarge 3s ease-in-out infinite;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* ----- */
/* title */
/* ----- */
.decorate {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  opacity: 0;
  animation: fadeIn 1.7s ease-in 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

.decorate div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(101, 64, 136, 0.6);
}

.decorate::before,
.decorate::after {
  content: "";
  display: flex;

  margin: 0 20px;
  width: 35vw;
  height: 20px;
  background-image: url("../../../public/img/Employment/line.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 30vmax;
  height: 85px;
  color: rgb(106, 35, 109);
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  animation: slideDown 1.5s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}

.title h1 {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

/* ----- */
/* 显示器 */
/* ----- */
.laptop {
  position: relative;

  width: 40vmax;
  height: 25vmax;
  border-radius: 20px;
  border: 1px solid black;
  border-width: 14px 14px 35px;
  background-color: rgb(255, 255, 255);
}

.laptop:after {
  content: "";
  position: absolute;
  bottom: -35px;

  margin: 0px 0 0 -75px;
  width: calc(40vmax + 118px);
  height: 30px;
  background-image: linear-gradient(
    to top,
    rgb(109, 109, 109),
    rgba(202, 202, 202, 1)
  );
  border-radius: 10px 10px 30px 30px;
}

.laptop:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);

  width: 250px;
  height: 15px;
  background: #ffffff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: 1;
  box-shadow: inset 0px 0px 8px gray;
}

.laptop_content {
  height: calc(25vmax - 45px);
  border-radius: 7px;
}

.phone {
  width: 15vmax;
  height: 25vmax;
}

.phone:after {
  display: none !important;
}

.phone:before {
  display: none !important;
}

.phone .laptop_content {
  height: calc(25vmax - 49px);
}

.laptop_content iframe {
  transform-origin: top left;
  transform: scale(0.25);

  width: 400%;
  height: calc((25vmax - 49px) * 4);
  border: none;
  border-radius: 7px;
}

.phone iframe {
  transform: scale(0.4);

  width: 250%;
  height: calc((25vmax - 49px) * 2.5);
}

/* ----- */
/* intro */
/* ----- */
.infoContent_container {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;

  margin: auto;
  width: 99vw;
  max-width: 1000px;
  padding-top: 150px;
}

.subInfoContent_container {
  display: grid;
  grid-template-columns: 45% 55%;
  /* align-items: center; */
}

.device {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0;
  padding: 20px;
  width: 100%;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  border: 2px solid white;
  opacity: 0;
  animation: fadeIn 1s ease-in 1s forwards;
}

.subsubInfoContent_container div {
  opacity: 0;
  animation: fadeIn 1s ease-in 2s forwards;
}

.url {
  text-align: center;
  padding: 50px !important;
}

.url span {
  cursor: pointer;
}

.url span:hover {
  border-bottom: 4px double rgb(179, 147, 172);
}

.subsubInfoContent_container > div {
  padding: 10px 10px;
  margin: 20px;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  border: 2px solid white;
}

.subsubInfoContent_container > div h2 {
  text-align: center;

  margin: 0px;
  border-bottom: 4px double rgb(179, 147, 172);
  border-width: 100%;
}

.infoContent_container ul {
  padding-left: 0;
}

.infoContent_container li {
  list-style-position: inside;
}

.intro {
  display: grid;
  grid-template-columns: auto auto;
}

.intro div:has(img) {
  display: grid;
  justify-items: center;
  align-items: center;
}

.forShake:hover {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* Gallery */
.imgGallery_container {
  position: sticky;
  top: 40vmin;

  margin: 20px 0;
  height: 50vh;
  width: 100%;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  border: 2px solid white;
  opacity: 0;
  animation: fadeIn 1s ease-in 1.5s forwards;
}

.imgGallery_container img {
  height: var(--h);
  max-width: var(--w);
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
  #ProjectDetail main {
    position: absolute;
    top: 0;
    left: 50%;
    transform: scale(0.6) translateX(-50%);
    transform-origin: top left;
  }

  .decorate {
    display: none;
  }

  .infoContent_container {
    padding-top: 200px;
  }

  .subInfoContent_container {
    display: block;
  }

  .imgGallery_container {
    position: relative;
    margin-top: -150px;
  }

  .infoContent {
    margin-top: 200px;
  }
}
