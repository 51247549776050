/* ------- */
/* Overall */
/* ------- */
#Projects {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/img/bg/6384984.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

#Projects main {
  width: 100vw;
  height: 100vh;
}

.bgFrame {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 35vw;
  height: 35vw;
  background-image: url("../../../public/img/bg/bgFrame2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

.bgFrame2 {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(-90deg);

  width: 35vw;
  height: 35vw;
  background-image: url("../../../public/img/bg/bgFrame2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

.bgFrame3 {
  position: fixed;
  top: 0;
  left: 0;
  transform: rotate(90deg);

  width: 20vw;
  height: 20vw;
  background-image: url("../../../public/img/bg/bgFrame2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

.bgFrame4 {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(-90deg);

  width: 20vw;
  height: 20vw;
  background-image: url("../../../public/img/bg/bgFrame2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: -1;
}

/* ----- */
/* title */
/* ----- */

.title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 30vmax;
  height: 85px;
  color: rgb(106, 35, 109);
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
    inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgb(128, 128, 128, 0.35),
    rgb(228, 225, 225, 0.35),
    rgb(255, 255, 255, 0.35),
    rgb(255, 255, 255, 0.35)
  );
  backdrop-filter: blur(5px);
  animation: slideDown 1.5s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}

.title h1 {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
}

/* ---- */
/* menu */
/* ---- */
.decorate {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  opacity: 0;
  animation: fadeIn 1.7s ease-in 1s forwards;
  z-index: 10;
  pointer-events: none;
}

.decorate > div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(101, 64, 136, 0.6);
  font-size: 0.8rem;
  color: rgb(106, 35, 109);
  animation: enlarge 3s ease-in-out infinite;
  cursor: pointer;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.decorate > div:hover .decorate > div {
  opacity: 0.5;
}

.decorate::before,
.decorate::after {
  content: "";
  display: flex;

  margin: 0 20px;
  width: 35vw;
  height: 20px;
  background-image: url("../../../public/img/Employment/line.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menu {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  padding: 25px;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 14px;
  border: 1px solid white;
  z-index: 10;
  opacity: 0;
}

.menu div {
  padding: 2px;
  cursor: pointer;
}

.menu div:hover {
  background-color: rgb(255, 255, 255, 0.6);
  border-radius: 7px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  opacity: 0.5;
}

.icon {
  width: 25px;
  height: auto;
}

/* -------------- */
/* card container */
/* -------------- */
.projectsIntroList_container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  margin: auto;
  padding: 0;
  height: 99vh;
  width: 100%;
  max-width: 700px;
  opacity: 0;
  animation: fadeIn 1s linear 1s forwards;
  pointer-events: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

.projectsIntroList {
  display: grid;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);

  height: fit-content;
  width: 100%;
}

.threeD {
  display: grid;
  transform-style: preserve-3d;
  perspective: 2500px;

  margin: auto;
}

.projectsIntro_container {
  display: grid;

  margin: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 3px solid white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.projectsIntro {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-row: 30% 70%;
  justify-content: center;
  align-items: center;

  margin: auto;
  padding: 20px;
  padding-right: 0;
  max-width: 600px;
  height: fit-content;
  color: rgb(106, 35, 109);
  background-color: rgb(255, 235, 252, 0.3);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 14px;
  pointer-events: none;
  z-index: 2;
}

.projectsIntro h1 {
  margin-top: 0;
}

.projectsIntro img {
  border-radius: 14px;
  border: 5px solid white;
  pointer-events: none;
}

.forBlur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 14px;
  background-color: rgb(255, 235, 252, 0.3);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  z-index: 0;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  padding: 16px;
  width: auto;
  background-color: rgba(165, 194, 219, 0.2);
  color: inherit;
  font-weight: bold;
  font-size: 40px;
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
}

.prev:hover,
.next:hover {
  background-color: rgb(0, 0, 0, 0.6);
}

.prev {
  left: -80px;
}

.next {
  right: -80px;
}

.detail {
  position: absolute;
  bottom: 20px;
  right: 20px;

  padding: 10px 20px;
  background-color: rgba(169, 104, 199, 0.4);
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
  border: 4px double white;
  border-radius: 7px;
  pointer-events: all;
}

.detail:hover {
  border: 4px solid white;
  opacity: 0.7;
}

.page {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);

  padding: 5px 10px;
  background-color: rgb(255, 235, 252, 0.3);
  color: rgb(106, 35, 109);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2),
    inset -0.1px -0.1px 10px rgba(255, 255, 255, 0.2),
    inset 0.1px 0.1px 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  pointer-events: none;
  opacity: 0;
  animation: slideUp 1s linear 1s forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    bottom: 0px;
  }

  to {
    opacity: 01;
    bottom: 70px;
  }
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
  #Projects main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: scale(0.6) translate(-50%, -50%);
    transform-origin: top left;
  }

  .decorate {
    display: none;
  }

  .prev,
  .next {
    display: none;
  }

  .menu {
    display: none;
  }

  .projectsIntroList_container {
    height: 150vh;
    width: 450px;
  }

  .projectsIntro {
    max-width: 400px;
  }

  .page {
    bottom: -80px;
  }
}
