/* -------- */
/* 小屏Menu */
/* -------- */
.menuS {
    display: none;
    position: fixed;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);

    width: 100px;
    height: 100px;
    background-image: url("../../../public/img/Employment/menu.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    animation: fadeIn 1s ease-in 1s forwards;
}

.menuS:root {
    --deg: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.icon {
    width: 25px;
    height: auto;
}

.menuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: rotate(var(--deg)) translateX(100px) rotate(calc(var(--deg) * -1)); */

    padding: 5px;
    border-radius: 7px;
    box-shadow:
        2px 2px 5px rgba(0, 0, 0, 0.2),
        inset -0.1px -0.1px 1px rgba(0, 0, 0, 0.2),
        inset 0.1px 0.1px 1px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(45deg, rgb(128, 128, 128, 0.35), rgb(228, 225, 225, 0.35), rgb(255, 255, 255, 0.35), rgb(255, 255, 255, 0.35));
    backdrop-filter: blur(5px);
    cursor: pointer;
    transition: 1s;
}

/* -------- */
/* 屏幕大小  */
/* -------- */
@media screen and (max-width: 600px) {
    .menuS {
        display: block;
    }
}