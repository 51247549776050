.imgGallery {
  position: relative;

  padding: 0;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
}

.slides {
  display: grid;
  grid-template-columns: repeat(6, 100%);
  position: absolute;
  left: 0px;

  height: 100%;
  width: 100%;
}

.slide {
  position: relative;

  height: 100%;
}

.slide div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.slide img {
  width: auto;
  height: 60vh;
  max-height: 550px;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 14px;
}

.hiddenImage {
  display: none;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  padding: 16px;
  width: auto;
  background-color: rgb(240, 248, 255, 0.2);
  color: inherit;
  font-weight: bold;
  font-size: 40px;
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
  pointer-events: none;
}

.prev:hover,
.next:hover {
  background-color: rgb(0, 0, 0, 0.6);
}

.prev {
  left: 15px;
}

.next {
  right: 15px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 10vh !important;
  height: 10vh !important;
  border: 10px solid #c4a9da;
  border-top: 10px solid rgb(112, 29, 129);
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.hiddenLoader {
  display: none !important;
}
