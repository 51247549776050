.bg {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;

    z-index: -1;
}

.cubes {
    position: absolute;
    top: 0;
    right: 0;

    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.cubes li {
    display: block;
    position: absolute;
    bottom: -150px;

    list-style: none;
    border-radius: 10px;
    animation: bgSlide 5s linear infinite;
}

.cubes li:nth-child(1) {
    left: 25%;

    width: 80px;
    height: 80px;
}

.cubes li:nth-child(2) {
    left: 10%;

    width: 40px;
    height: 40px;
    animation-delay: 4s;
    animation-duration: 10s;
}

.cubes li:nth-child(3) {
    left: 70%;

    width: 40px;
    height: 40px;
    animation-delay: 4s;
}

.cubes li:nth-child(4) {
    left: 50%;

    width: 60px;
    height: 60px;
    animation-duration: 18s;
}

.cubes li:nth-child(5) {
    left: 75%;

    width: 40px;
    height: 40px;
}

.cubes li:nth-child(6) {
    left: 90%;

    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.cubes li:nth-child(7) {
    left: 40%;

    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.cubes li:nth-child(8) {
    left: 80%;

    width: 50px;
    height: 50px;
    animation-delay: 15s;
    animation-duration: 30s;
}

.cubes li:nth-child(9) {
    left: 30%;

    width: 50px;
    height: 50px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.cubes li:nth-child(10) {
    left: 0%;

    width: 150px;
    height: 150px;
    animation-duration: 11s;
}

@keyframes bgSlide {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 0.2;
    }

    100% {
        transform: translateY(-110vh) rotate(720deg);
        opacity: 0;
    }
}